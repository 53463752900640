.hidden{
    display:none;
    position: absolute
}

.NavigationWrapper{
    border-bottom: solid gray 1px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 35px;
    margin-top: 20px;
    height: 4rem;
}

.TopLeft{
    display: flex;
    justify-content: center;
}

#logo{
    width:40px;
}

#ProfileWrapper{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    height: 50px;
    /* border: red solid */
}

#ModalLinks{
    display: flex;
    justify-content: flex-end;
    font-size: 20px;
}

#HomeLink{
    font-size: 35px;
    margin-left:.5rem;
}

#UserButton{
    font-size: 30px;
    color: rgb(223, 96, 96);
    display: flex;
    justify-content: flex-end;
    margin: 2px 5px;
}

#LogoutButton{
    border-radius: 30px;
    font-size: 16px;
    border: none;
    background: rgb(223, 96, 96);
    color: white;
    width: 6rem;
    height: 2rem;
    margin: .5rem 0rem;
}

.profile-dropdown{
    border: rgb(223, 96, 96)solid;
    background: white;
    text-align: center;
    color: rgb(223, 96, 96);
    font-size: 16px;
    width: 50%;
    padding: 0;
    z-index: 1000
}

.topRightLinks {
    /* border: red solid; */
    display: flex;
    align-items: center;
}

.buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(223, 96, 96);
    border: rgb(223, 96, 96) solid;
    border-radius: 25px;
    width: 100px;
    height: 3rem;

}

#hamburger{
    font-size:1.5rem;
}


#NewSpotLink{
    margin-right:1rem;
}


/* hovers and what not */
#ModalLinks:hover{
    cursor: pointer
}

.TopLeft:hover {
    cursor: pointer
}

#UserButton, #hamburger:hover {
    cursor: pointer
}
