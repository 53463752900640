 * {
     font-family: 'Prompt', sans-serif;
     font-family: 'Red Hat Display', sans-serif;
 }

 h1 {
     margin: 1.5rem;
     color: rgb(92, 91, 91);
 }

#modal-content {
    height: 80%
}

 .SignUpWrapper {
     display: flex;
     flex-direction: column;
     align-items: center;
     height: 28rem;
     width: 25rem;
     border-radius: 20px
 }

 .Form {
     display: flex;
     flex-direction: column;
     align-items: center;
 }


 input {
     border: none;
     box-shadow: 3px 3px 6px 1px rgb(223, 96, 96);
     padding: .5rem 2rem;
     margin-bottom: 1rem;
     display: flex;
     justify-content: flex-end;
     /* flex-flow: space-between */
 }

 #SubmitButton {
     border-radius: 30px;
     font-size: large;
     border: none;
     background: rgb(223, 96, 96);
     color: white;
     width: 5rem;
     display: flex;
     /* justify-content: flex-end; */
     justify-content: center;
     align-items: center;
     margin: 1rem 4rem;
     height: 2rem;
     /* width:5rem */
 }

 #Disabled {
     border-radius: 30px;
     font-size: large;
     border: none;
     background: lightgray;
     color: white;
     width: 5rem;
     height: 2rem;
     display: flex;
     /* justify-content: flex-end; */
     justify-content: center;
     align-items: center;
    margin: 1rem 4rem;
    height: 2rem;
 }
