/* TODO Add site wide styles */
 *{
    list-style: none;
    margin: 0;
    text-decoration: none;
    background-color: rgb(255, 249, 233);
 }


.links{
    font-family: 'Prompt', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    text-decoration: none;
    color:rgb(223, 96, 96);
    /* font-size: 30px; */
}

.buttons{
    font-family: 'Prompt', sans-serif;
    font-family: 'Red Hat Display', sans-serif;
    border: none;
    background-color: white;
}


.errors{
    color: rgb(223, 96, 96);
}
