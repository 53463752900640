.empty{
    color: rgb(186, 176, 176);
    /* border: red */

}

.filled{
    color: rgb(223, 96, 96);
    border: red
}

.Stars{
    display: flex;
    margin: 0;
    padding:0
}

#modal-content{
    height: 0rem;
}

#DeleteModal{
    border-radius: 20px;
}

textarea{
    background-color: white;
}
