 *{
   font-family: 'Prompt', sans-serif;
   font-family: 'Red Hat Display', sans-serif;
 }

 h1{
   margin: .5rem;
   color: rgb(92, 91, 91);
 }

 .LoginWrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    height: 20rem;
    width: 20rem;
    border-radius: 20px;
    margin: 2rem 0;
 }

form{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
 }


 #UserName, #Password{
    border: none;
    box-shadow: 3px 3px 6px 1px rgb(223, 96, 96);
    padding: .5rem 2rem;
    margin-bottom: 1rem ;
    display: flex;
    justify-content: flex-end;
    /* flex-flow: space-between */
 }

#ButtonWrapper {
   display: flex;
   flex-direction: column;
   align-items: center
}

#SubmitButton{
    border-radius: 30px;
    font-size: large;
    border: none;
    background: rgb(223, 96, 96);
    color: white;
    width:5rem;
    height: 2rem;
    display: flex;
   /* justify-content: flex-end; */
    justify-content: center;
    align-items: center;
    margin: 0rem 4rem;
}

#Disabled{
   border-radius: 30px;
      font-size: large;
      border: none;
      background: lightgray;
      color: white;
      width: 5rem;
      height: 2rem;
      display: flex;
      /* justify-content: flex-end; */
      justify-content: center;
      align-items: center;
      margin: 0rem 4rem;
}


#DemoUser {
   border-radius: 30px;
   font-size: large;
   border: none;
   background: rgb(223, 96, 96);
   color: white;
   width: 7rem;
   height: 2rem;
   display: flex;
   /* justify-content: flex-end; */
   justify-content: center;
   align-items: center;
   margin: .5rem 4rem;
}

#modal-content {
   height: 20rem
}
