
#DeleteModal{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

h3{
    width: 20rem;
    text-align: center;
    padding: .5rem
}

#SubmitButton {
    border-radius: 30px;
    font-size: large;
    border: none;
    /* background: rgb(223, 96, 96); */
    color: white;
    width: 10rem;
    height: 2rem;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: center;
    align-items: center;
    margin: 1rem 4rem;
}

#DeclineButton {
    border-radius: 30px;
    font-size: large;
    border: none;
    /* background: rgb(223, 96, 96); */
    color: rgb(223, 96, 96);
    width: 10rem;
    height: 2rem;
    display: flex;
    /* justify-content: flex-end; */
    justify-content: center;
    align-items: center;
    margin: 1rem 4rem;
}

#SubmitButton:hover{
    cursor: pointer;
}
