
#BodyWrapper{

}


#SpotsWrapper{
    margin-left: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

}

h1{
    margin: 0 2rem
}

#SingleSpot{
    /* border:red solid; */
    width: 22%;
    margin: .5rem .5rem;
    /* display:flex */
}

.SpotInfo{
    /* border: red solid; */
    color: rgb(186, 82, 82);
    font-size: 10pt;
    margin:0;
}

#TopLine, #SpotRating{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%
}

#SpotImage{
    width: 100%;
    height: 60%
}

#SingleSpot:hover {
    cursor: pointer;
}
