#SpotWrapper, #ReviewsWrapper {
    margin: 1rem 4rem;
    padding-bottom: 2rem;
    display:flex;
    flex-direction: column;
    border-bottom: gray solid
}

.HeaderBlock {
    /* margin: 1rem 0rem; */
    /* padding: 1rem 0rem */
}

#SpotName,
#SpotLocation {
    margin: .5rem 0rem
}

.PhotoBlock{
    width:100%;
    display: flex;

}

#PreviewImg {
    width: 49%;
    margin: .5%
}

#MiniImg{
    width: 49%;
    height: 47.4%;
    margin: .5%
}

.SpotInfo{
    display: flex;
    justify-content: space-between;
    padding: .5rem 0;
}

.Description{
    width: 35rem
}

.ReservationInfo {
    width: 35%;
    padding: 8px;
    border: gray solid;
    border-radius: 10px;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.TopLine{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 6px
}

#price{
    font-size:18pt;
}

h3{
    padding: 0;
}


#ReserveButton{
    width: 90%;
    font-size: 14pt;
    background-color:rgb(223, 96, 96);
    color:white;
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px gray;
    padding: .3rem;
    margin: .25rem 0rem;
}

#ModalButton {
    /* border: red solid; */
    width: 30%;
    font-size: 14pt;
    background-color: rgb(223, 96, 96);
    color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px gray;
    padding: .3rem;
    /* margin: 2rem 0; */
    margin: 1rem 0rem;
}

#username, #createdAt {
    font-size: larger;
}

#reviewtext{
    padding: .5rem 0
}


#ModalButton:hover, #ReserveButton:hover {
    cursor: pointer;
}

#hidden{
    display:none
}
